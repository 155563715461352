@import '../../styles/import';

.btn {
  font-family: Public Sans;
  font-weight: 400;
  border-radius: 0.5rem;
  height: 4.6rem;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.2rem;
  box-sizing: border-box;
  padding: 0 2.2rem;
  display: flex;
  align-items: center;

  &--primary {
    background-color: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;
    transition: transform 50ms ease-in;

    &:active {
      transform: scale((0.9));
    }
  }

  &--primary-disabled {
    background-color: transparent;
    color: $color-gray-mid;
    border: 1px solid $color-gray-mid;
    cursor: not-allowed;
  }

  &--secondary {
    background-color: $color-primary;
    color: $color-gray-white;
    border: transparent;
    transition: transform 50ms ease-in;

    &:active {
      transform: scale((0.9));
    }
  }

  &--secondary-disabled {
    background-color: $color-gray-mid;
    color: $color-gray-white;
    border: transparent;
    cursor: not-allowed;
  }
}
