@import '../../styles/import';

.project-details-form {
  &__heading {
    color: $color-gray-dark;
    font-weight: 400;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid $color-gray-mid;
  }

  &__form {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    & > *:first-child {
      margin-top: 2.4rem;
    }
  }

  &__form-row {
    display: flex;
    gap: 2.4rem;

    & > * {
      flex: 1 1 0;
    }

    &--intrinsic-width {
      align-self: flex-end;

      & > * {
        flex: 0 1 auto;
      }
    }
  }
}
