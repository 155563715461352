@import '../../styles/import';

$padding-inline: 4.8rem;

.header {
  padding: 1.8rem $padding-inline;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: $color-gray-teal;

  &__logo {
    height: 10.2rem;
  }

  &__login-btn {
    font-family: Public Sans, sans-serif;
    position: absolute;
    right: $padding-inline;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: $color-primary;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
