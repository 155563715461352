@import '../../styles/import';

.products-form {
  &__heading {
    color: $color-gray-dark;
    font-weight: 400;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid $color-gray-mid;
  }

  &__products-form-row {
    display: flex;
    align-items: flex-end;

    &:first-child {
      margin-top: 2.4rem;
    }

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    & > * {
      &:first-child {
        margin-right: 2.4rem;
        flex: 0 0 10.4rem;
      }

      &:nth-child(2) {
        flex: 1 1 0;
      }

      &:last-child {
        margin-left: 7.6rem;
        margin-bottom: 0.8rem;
        flex: 0 0 10rem;
      }
    }

    &--btn {
      margin: 0 !important;
      justify-content: space-between;

      & > * {
        margin: 0 !important;
        flex: 0 0 auto !important;
      }
    }
  }
}
