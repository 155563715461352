$color-primary: #2076cb;
$color-gray-light: #e5e5e5;
$color-gray-lighter: #e0e0e0;
$color-gray-lightest: #f4f7fa;
$color-gray-mid: #bdbdbd;
$color-gray-mid-2: #828282;
$color-gray-white: #ffffff;
$color-gray-dark: #333333;
$color-gray-black: #000000;
$color-gray-teal: #f5f8fb;
