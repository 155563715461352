@import '../../styles/import';

.result {
  &__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3.2rem;
    border-bottom: 1px solid $color-gray-lighter;
  }

  &__total-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__heading {
    font-family: Public Sans, sans-serif;
    font-weight: 700;
    color: $color-gray-mid-2;
    border-bottom: 1px solid $color-gray-mid-2;
    padding-bottom: 0.8rem;
    display: inline-block;
    margin-bottom: 0.8rem;
    align-self: flex-start;
  }

  &__cost {
    font-family: Lato, sans-serif;
    font-size: 4.8rem;
    line-height: 5.8rem;
    font-weight: 600;
    color: $color-primary;
  }

  &__project-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__details {
    font-family: Public Sans, sans-serif;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    color: $color-primary;
    text-transform: capitalize;
  }

  &__project-name {
    font-family: Public Sans, sans-serif;
    font-size: 3.2rem;
    line-height: 3.8rem;
    font-weight: 400;
    color: $color-primary;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 3.2rem;
  }

  &__product-list {
    list-style-type: none;
    margin-bottom: 2.5rem;
    align-self: stretch;
  }

  &__product {
    display: flex;
    gap: 3.2rem;
    padding-block: 0.8rem;

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-lighter;
    }
  }

  &__type,
  &__name,
  &__count {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-family: Public Sans, sans-serif;
    color: $color-gray-dark;
    font-weight: 400;
  }

  &__type {
    flex-shrink: 0;
    text-transform: uppercase;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__count {
    margin-left: auto;
  }
}
