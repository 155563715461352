@import '../../styles/import';

.card {
  background-color: $color-gray-white;
  border-radius: 1rem;
  box-shadow: 0px 8px 24px rgba(217, 217, 217, 0.5);
  width: 72.4rem;
  margin: auto;
  padding: 3.2rem;

  &--wide {
    width: 90rem;
  }

  &--narrow {
    width: 43.2rem;
  }
}
