@import '../../styles/import';

.login {
  font-family: Public Sans, sans-serif;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin-bottom: 5.3rem;
  }

  &__logo-img {
    height: 7.2rem;
  }

  &__welcome-text {
    text-align: center;
    margin-bottom: 8.3rem;
  }

  &__text-top {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 400;
    color: $color-gray-mid-2;
    display: block;
  }

  &__text-bottom {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 600;
    color: $color-primary;
    display: block;
  }

  &__btn-box {
    align-self: stretch;
  }

  &__btn {
    width: 100%;
    height: 4.2rem;
    border: 1px solid $color-primary;
    border-radius: 0.5rem;
    background-color: $color-gray-white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
    transition: transform 50ms ease-in;

    &:active {
      transform: scale((0.9));
    }
  }

  &__btn-text {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 400;
    color: $color-primary;
  }

  &__btn-icon {
    height: 2.4rem;
    width: 2.4rem;
  }
}
