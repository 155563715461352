@import '../import';

body {
  font-family: Poppins, sans-serif;
  background-color: $color-gray-teal;
}

.--flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.--center {
  text-align: center;
}

.--clr {
  clear: both;
}

.--pad-margin-0 {
  margin: 0;
  padding: 0;
}

@each $tag, $value in $heading-fonts {
  #{$tag} {
    font-size: map-get($value, font-size);
    line-height: map-get($value, line-height);
  }
}
